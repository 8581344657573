//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-312:_1856,_6784,_9200,_5816,_9424,_6508,_1776,_9856;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-312')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-312', "_1856,_6784,_9200,_5816,_9424,_6508,_1776,_9856");
        }
      }catch (ex) {
        console.error(ex);
      }